<script setup>
import {
  useViewVehicles,
  useGenerateVehicleBill,
  useDownloadVehicleSticker,
  useBulkDownloadVehicleStickers,
} from "./view_vehicles_logic";
import ViewVehiclesTable from "./ViewVehiclesTable.vue";
import ViewVehicle from "./ViewVehicle.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import { computed, ref } from "vue";
import { useRouterFromInstance, useDownloadFile } from "@/utils/vue_helpers";
import {
  resetSnackbarOnMount,
  snackbarData,
} from "@/components/utils/snackbar/logic";
import LoadingArea from "../utils/LoadingArea.vue";
import Pagination from "../utils/Pagination.vue";
import PayForVehicle from "./PayForVehicle.vue";
import { routes } from "@/router/routes";
import {
  downloadBlob,
  getVehicleSticker,
  vehicleStickerExpired,
} from "@/utils/functions";
import FilterValues from "../utils/FilterValues.vue";
import FilterDialog from "../utils/FilterDialog.vue";
import FilterButton from "../utils/FilterButton.vue";

resetSnackbarOnMount();

const { vehiclesData, searchModel, filters } = useViewVehicles();

const pages = {
  VIEW_VEHICLES_TABLE: 0,
  VIEW_VEHICLE_DETAILS: 1,
  VIEW_PAY_FOR_VEHICLE: 2,
};
const currentPage = ref(pages.VIEW_VEHICLES_TABLE);

const vehicleUnderView = ref(null);
const vehicleUnderViewNotEmpty = computed(() => {
  return vehicleUnderView.value !== null;
});

function moveToViewVehiclePage(vehicle) {
  vehicleUnderView.value = vehicle;
  currentPage.value = pages.VIEW_VEHICLE_DETAILS;
}

function moveToViewVehicleTable() {
  currentPage.value = pages.VIEW_VEHICLES_TABLE;
}

const vehiclesToPayFor = ref([]);
function onPayForVehiclesClicked(vehicles) {
  vehiclesToPayFor.value = vehicles;
  currentPage.value = pages.VIEW_PAY_FOR_VEHICLE;
}

const generateVehicleBill = useGenerateVehicleBill();
const { router } = useRouterFromInstance();
function onGoToPaymentSectionClicked({
  period,
  remarks,
  subCountyId,
  vehicles,
}) {
  const firstVehicle = vehicles[0];
  const vehicleAccount = firstVehicle.account;
  const vehicleIds = vehicles.map((vehicle) => vehicle.id);
  generateVehicleBill.mutate(
    {
      vehicleAccountNumber: vehicleAccount.accountNo,
      createdBy: vehicleAccount.created_by,
      remarks,
      period,
      subCountyId,
      vehicleIds,
    },
    {
      onSuccess(bill) {
        snackbarData.text = "Vehicle Bill Generated";
        snackbarData.color = "success";
        snackbarData.open = true;

        const paymentRoute = routes.payment.withQuery({
          billNumbersToPayAsString: bill.bill_number,
          directPayment: true,
          billsToPay: [bill],
        });
        router.push(paymentRoute);
      },
    }
  );
}

const { downloadVehicleStickerMutation: downloadVehicleSticker } =
  useDownloadVehicleSticker();

function onDownloadVehicleStickerClicked(vehicle) {
  const sticker = getVehicleSticker(vehicle);
  const hasNoSticker = !!!sticker;
  if (hasNoSticker) {
    snackbarData.text = "No Sticker Found";
    snackbarData.color = "error";
    snackbarData.open = true;
    return;
  }
  if (vehicleStickerExpired(sticker)) {
    snackbarData.text = "Sticker Expired";
    snackbarData.color = "error";
    snackbarData.open = true;
    return;
  }

  snackbarData.text = "Downloading Vehicle Sticker";
  snackbarData.color = "success";
  snackbarData.open = true;
  downloadVehicleSticker.mutate(
    { vehicle },
    {
      onSuccess(data) {
        downloadBlob({ blob: data, filename: "vehicle-sticker" });
      },
    }
  );
}

const filterDialogOpen = ref(false);

const { downloadFile } = useDownloadFile();

function onDownloadVehicleListClicked() {
  downloadFile({ shouldDownloadVehicleList: true });
}

const { bulkDownloadVehicleStickersMutation: bulkDownloadVehicleSticker } =
  useBulkDownloadVehicleStickers();

function onBulkDownloadVehicleStickersClicked(vehicles) {
  let atLeastOneValidSticker = false;
  for (const selectedVehicle of vehicles) {
    const sticker = getVehicleSticker(selectedVehicle);
    if (!vehicleStickerExpired(sticker)) {
      atLeastOneValidSticker = true;
      break;
    }
  }
  if (!atLeastOneValidSticker) {
    snackbarData.text = "All selected vehicles have expired";
    snackbarData.color = "error";
    snackbarData.open = true;
    return;
  }

  const vehicleIds = vehicles.map((vehicle) => vehicle.id);
  snackbarData.text = "Downloading Bulk Vehicle Stickers";
  snackbarData.color = "success";
  snackbarData.open = true;
  bulkDownloadVehicleSticker.mutate({ vehicleIds });
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items touchless v-model="currentPage">
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <div class="text-h6 text-center font-weight-bold primary--text">
              Vehicles
            </div>
          </v-col>
        </v-row>
        <v-row justify-md="center" align-md="end" dense>
          <v-col cols="3" class="d-md-flex justify-md-end">
            <FilterButton @click="filterDialogOpen = true"> </FilterButton>
          </v-col>
          <v-col cols="12" md="6">
            <LabelledField label="Search">
              <v-text-field
                class="background text-field"
                outlined
                dense
                v-model="searchModel"
                clearable
              />
            </LabelledField>
          </v-col>
          <v-col cols="3" />
        </v-row>
        <LoadingArea :loading="vehiclesData.fetchingData" class="mt-4">
          <v-row dense>
            <v-col cols="12">
              <FilterValues
                :filterValuesForDisplay="filters.filterValuesForDisplay.value"
                :clear-all-filters="filters.clearAllFilters"
                :at-least-one-filter-active="
                  filters.atLeastOneFilterActive.value
                "
                showDivider
              ></FilterValues>
            </v-col>
          </v-row>

          <v-row class="no-gutters">
            <v-col cols="12">
              <ViewVehiclesTable
                :vehicles="vehiclesData.fetchedData"
                :loading="vehiclesData.fetchingData"
                :bulkDownloadingStickers="
                  bulkDownloadVehicleSticker.isLoading.value
                "
                @viewVehicle="moveToViewVehiclePage"
                @downloadSticker="onDownloadVehicleStickerClicked"
                @payForVehiclesClicked="onPayForVehiclesClicked"
                @downloadVehicleListClicked="onDownloadVehicleListClicked"
                @bulkDownloadStickersClicked="onBulkDownloadVehicleStickersClicked"
              >
              </ViewVehiclesTable>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <Pagination
                :current-page="vehiclesData.paginationData.currentPage"
                :total-number-of-items="vehiclesData.paginationData.total"
                :items-per-page="vehiclesData.paginationData.perPage"
                @paginationClicked="vehiclesData.setNewPage($event)"
              >
              </Pagination>
            </v-col>
          </v-row>
        </LoadingArea>
      </v-tab-item>
      <v-tab-item :value="pages.VIEW_VEHICLE_DETAILS">
        <v-row>
          <v-col cols="12">
            <ViewVehicle
              v-if="vehicleUnderViewNotEmpty"
              :vehicle="vehicleUnderView"
              showGoBack
              @downloadSticker="onDownloadVehicleStickerClicked"
              @goBackClicked="moveToViewVehicleTable"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="pages.VIEW_PAY_FOR_VEHICLE">
        <v-row>
          <v-col cols="12">
            <PayForVehicle
              :vehicles="vehiclesToPayFor"
              :generatingVehicleBill="generateVehicleBill.isLoading.value"
              @goBackClicked="moveToViewVehicleTable"
              @payVehiclesClicked="onGoToPaymentSectionClicked"
            ></PayForVehicle>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <FilterDialog
      :filters="filters.filtersRef.value"
      :loading="vehiclesData.fetchingData"
      :dialogOpen.sync="filterDialogOpen"
      :clear-all-filters="filters.clearAllFilters"
      :at-least-one-filter-active="filters.atLeastOneFilterActive.value"
    />
  </v-container>
</template>
