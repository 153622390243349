<script setup>
import { arrayNotEmpty, capitalizeWordsInSentence } from "@/utils/functions";
import { countyDetails } from "@/utils/vue_helpers";
import { computed, onMounted, ref } from "vue";
import { useDownloadVehicleCsvData } from "./view_vehicles_logic";
import JsonCsv from "vue-json-csv";

const title = computed(() => {
  return capitalizeWordsInSentence(countyDetails.value.name);
});

const vehicleData = useDownloadVehicleCsvData();

const vehicles = ref([]);

const csvData = computed(() => {
  const vehiclesEmpty = !arrayNotEmpty(vehicles.value);
  if (vehiclesEmpty) {
    return [];
  }

  return vehicles.value.map((vehicle) => ({
    "Vehicle Reg No": vehicle.registrationNumber,
    "Account Name": vehicle.account.account_name,
    "Contact Person": vehicle.account.contact_person,
    "Phone No": vehicle.account.contact_phone_number,
    Capacity: vehicle.capacity.capacity,
    Subcounty: vehicle.subcounty.sub_county_name,
    Arrears: vehicle.memo_arrears,
    Penalty: vehicle.memo_penalty,
    "Monthly Fees": vehicle.capacity.sub_part_fee.amount,
    Balance: vehicle.memo_current_balance,
  }));
});

const fields = computed(() => {
  const csvDataEmpty = !arrayNotEmpty(csvData.value);
  if (csvDataEmpty) {
    return [];
  }

  const firstEntry = csvData.value[0];
  return Object.keys(firstEntry);
});

onMounted(() => {
  vehicleData.mutate(
    {},
    {
      onSuccess(vehiclesData) {
        vehicles.value = vehiclesData;
      },
    }
  );
});
</script>

<template>
  <div>
    <JsonCsv
      v-if="!vehicleData.isLoading.value"
      :header="title"
      :title="title"
      :data="csvData"
      name="Eportal-Vehicles.csv"
    >
      <slot name="button" :loading="vehicleData.isLoading.value"></slot>
    </JsonCsv>

    <v-skeleton-loader
      v-else
      type="button"
      class="skeleton-fill-width"
      width="140"
    ></v-skeleton-loader>
  </div>
</template>
