<script setup>
import { filterTypes } from "@/utils/constants";
import { computed } from "vue";
import FilterDialogItem from "./FilterDialogItem.vue";
import Table from "./Table.vue";

const props = defineProps({
  filters: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  dialogOpen: {
    type: Boolean,
    default: false,
  },
  clearAllFilters: {
    type: Function,
    default: null,
  },
  atLeastOneFilterActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:dialogOpen"]);

const innerDialogOpen = computed({
  get() {
    return props.dialogOpen;
  },
  set(newVal) {
    emit("update:dialogOpen", newVal);
  },
});
</script>

<template>
  <v-dialog v-model="innerDialogOpen" max-width="600">
    <v-card class="pb-2">
      <v-card-title class="d-flex flex-column align-start px-2 px-md-6">
        <div
          class="d-flex justify-space-between"
          :style="{
            width: '100%',
          }"
        >
          <div class="flex-grow-1">
            <span :class="{ 'text-body-2 font-weight-bold': $mobileBreakpoint }"
              >Search Filters</span
            >

            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              size="24"
              class="ml-4"
            />
            <v-btn
              v-if="atLeastOneFilterActive && !$mobileBreakpoint"
              small
              class="text-none ml-4"
              outlined
              color="secondary"
              @click="clearAllFilters"
              >Clear Filters <v-icon right>mdi-close</v-icon></v-btn
            >
          </div>
          <v-btn icon @click="innerDialogOpen = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-btn
          v-if="atLeastOneFilterActive && $mobileBreakpoint"
          small
          class="text-none"
          outlined
          color="secondary"
          @click="clearAllFilters"
          >Clear Filters <v-icon right>mdi-close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text class="px-0 px-md-6">
        <v-simple-table class="filter-dialog-table">
          <template #default>
            <tbody v-if="$mobileBreakpoint">
              <template v-for="(filter, index) in filters">
                <tr :key="index">
                  <td>{{ filter.name }}</td>
                </tr>
                <tr>
                  <td>
                    <FilterDialogItem :filter="filter"></FilterDialogItem>
                  </td>
                </tr>
              </template>
            </tbody>

            <tbody v-else>
              <tr v-for="(filter, index) in filters" :key="index">
                <td
                  :class="{ 'px-2 px-md-4 text-caption text-md-body-1': true }"
                >
                  {{ filter.name }}
                </td>
                <td>
                  <FilterDialogItem :filter="filter"></FilterDialogItem>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
:deep(.filter-dialog-table) {
  & tr {
    background-color: transparent !important;
  }

  & td {
    border-bottom: none !important;
  }
}
</style>
