<script setup>
import LabelledField from "@/components/utils/LabelledField.vue";
import BackButton from "@/components/utils/BackButton.vue";
import {
  userProfile,
  supportingFormData,
  getVueInstance,
  countyIsKirinyaga,
} from "@/utils/vue_helpers";
import { computed, ref, watch, watchEffect } from "vue";
import { fieldRequired } from "@/utils/input_validation";
import {
  arrayNotEmpty,
  formatVehicleCapacity,
  getFormattedMoney,
} from "@/utils/functions";
import { snackbarData } from "../utils/snackbar/logic";
import Table from "../utils/Table.vue";

const props = defineProps({
  vehicles: {
    type: Array,
    default: null,
  },
  generatingVehicleBill: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["payVehiclesClicked"]);

const form = ref(null);

const formValid = ref(false);

function checkVehicleSelected() {
  if (arrayNotEmpty(selectedVehicles.value)) {
    return true;
  }
  snackbarData.text = "Please select a vehicle";
  snackbarData.color = "error";
  snackbarData.open = true;
  return false;
}

function onFormSubmitted() {
  const formValid = form.value.validate();
  if (formValid && checkVehicleSelected()) {
    emit("payVehiclesClicked", {
      period: billingPeriod.value,
      remarks: remarks.value,
      subCountyId: subcounty.value,
      vehicles: selectedVehicles.value,
    });
  }
}

const selectedVehicles = ref([]);

const billingPeriod = ref(1);

const remarks = ref("");

const subcounty = ref(null);

const billingPeriods = computed(() => {
  const periods = [
    {
      name: "1 Month",
      value: 1,
    },
  ];
  if (!countyIsKirinyaga.value) {
    periods.push(
      {
        name: "2 Months",
        value: 2,
      },
      {
        name: "3 Months",
        value: 3,
      },
      {
        name: "4 Months",
        value: 4,
      },
      {
        name: "5 Months",
        value: 5,
      },
      {
        name: "6 Months",
        value: 6,
      },
      {
        name: "7 Months",
        value: 7,
      },
      {
        name: "8 Months",
        value: 8,
      },
      {
        name: "9 Months",
        value: 9,
      },
      {
        name: "10 Months",
        value: 10,
      },
      {
        name: "11 Months",
        value: 11,
      },
      {
        name: "12 Months",
        value: 12,
      }
    );
  }
  return periods;
});

const selectionState = {
  none: computed(() => selectedVehicles.value.length === 0),
  someVehicles: computed(
    () =>
      selectedVehicles.value.length !== 0 &&
      selectedVehicles.value.length < props.vehicles.length
  ),
  allVehicles: computed(
    () => selectedVehicles.value.length === props.vehicles.length
  ),
};

function headerCheckboxClicked() {
  if (selectionState.allVehicles.value) {
    selectedVehicles.value = [];
  } else {
    selectedVehicles.value = [...props.vehicles];
  }
}

watchEffect(() => {
  selectedVehicles.value = props.vehicles;
});

watch(selectedVehicles, (newVal) => {
  if (!arrayNotEmpty(newVal)) {
    formValid.value = false;
  } else {
    formValid.value = form.value?.validate() ?? false;
  }
});

const { vuetify } = getVueInstance();

const tableHeaders = computed(() => {
  const headers = [];
  const smallScreen = vuetify.breakpoint.smAndDown;
  const showRowCheckboxAsFirstItem = !smallScreen;
  const rowCheckboxShouldNotBeFirstItem = smallScreen;
  if (showRowCheckboxAsFirstItem) {
    headers.push({ text: "Checkbox", value: "checkbox" });
  }
  headers.push(
    { text: "Registration No", value: "registrationNumber" },
    { text: "Vehicle Type", value: "type" },
    { text: "Vehicle Capacity", value: "capacity" },
    { text: "Penalty", value: "penalty" },
    { text: "Arrears", value: "arrears" },
    { text: "Monthly Fees", value: "monthlyFees" },
    { text: "Total Outstanding", value: "totalOutstanding" }
  );
  if (rowCheckboxShouldNotBeFirstItem) {
    headers.push({ text: "Checkbox", value: "checkbox" });
  }
  return headers;
});

const total = computed(() => {
  return selectedVehicles.value.reduce((sum, currentVehicle) => {
    const currentBalance = currentVehicle.memo_current_balance;
    const shouldUseMonthlyFees = currentBalance <= 0;
    if (shouldUseMonthlyFees) {
      sum += parseFloat(currentVehicle.capacity.sub_part_fee.amount);
      return sum;
    }
    sum += parseFloat(currentBalance);
    return sum;
  }, 0);
});

function vehicleIsOverpaying(vehicle) {
  return vehicle.memo_current_balance < 0;
}

function getNumberOfOverpaidMonths(vehicle) {
  const monthlyFee = vehicle.capacity.sub_part_fee.amount;
  const currentBalance = vehicle.memo_current_balance;
  return Math.abs(Math.floor(currentBalance / monthlyFee));
}
</script>
<template>
  <v-container fluid>
    <v-form ref="form" v-model="formValid" @submit.prevent="onFormSubmitted">
      <v-row>
        <v-col cols="12" md="1">
          <BackButton @click="$emit('goBackClicked')">
            Back To Vehicles
          </BackButton>
        </v-col>
        <v-col
          cols="12"
          md="10"
          class="text-h6 text-center font-weight-bold primary--text"
        >
          Pay For Vehicles
        </v-col>
        <v-spacer v-if="!$mobileBreakpoint" />
      </v-row>
      <v-row
        ><v-col cols="12" class="text-center font-weight-bold pb-md-8">
          {{ userProfile.customer_name }}
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <LabelledField label="Billing Period" required
            ><v-select
              class="text-field-background"
              hide-details="auto"
              v-model="billingPeriod"
              :rules="[fieldRequired]"
              dense
              item-text="name"
              item-value="value"
              :items="billingPeriods"
              outlined
              :readonly="countyIsKirinyaga"
            >
            </v-select
          ></LabelledField>
        </v-col>

        <v-col cols="12" sm="6">
          <LabelledField label="Sub County" required
            ><v-select
              item-value="id"
              item-text="sub_county_name"
              v-model="subcounty"
              class="text-field-background"
              hide-details="auto"
              :rules="[fieldRequired]"
              :items="supportingFormData.subCounties"
              dense
              outlined
            ></v-select
          ></LabelledField>
        </v-col>
      </v-row>
      <v-row>
        <!-- list vehicles -->
        <v-col cols="12">
          <Table
            :headers="tableHeaders"
            :items="vehicles"
            emptyText="No Vehicles found"
            headClasses="font-weight-bold black--text"
          >
            <template #header.checkbox>
              <v-icon
                v-if="!$mobileBreakpoint"
                color="primary"
                @click="headerCheckboxClicked"
              >
                <template v-if="selectionState.none.value">
                  mdi-checkbox-blank-outline
                </template>
                <template v-else-if="selectionState.someVehicles.value">
                  mdi-minus-box
                </template>
                <template v-else> mdi-checkbox-marked </template>
              </v-icon>
              <div v-else>Select</div>
            </template>

            <template #item.checkbox="{ item: vehicle }">
              <v-checkbox
                dense
                hide-details="auto"
                v-model="selectedVehicles"
                :value="vehicle"
                :value-comparator="(a, b) => a.id === b.id"
              >
              </v-checkbox>
            </template>

            <template #item.type="{ item: vehicle }">
              {{ vehicle.type.type }}
            </template>

            <template #item.capacity="{ item: vehicle }">
              {{ formatVehicleCapacity(vehicle.capacity.capacity) }}
            </template>

            <template #item.penalty="{ item: vehicle }">
              {{ getFormattedMoney(vehicle.memo_penalty) }}
            </template>

            <template #item.arrears="{ item: vehicle }">
              {{ getFormattedMoney(vehicle.memo_arrears) }}
            </template>

            <template #item.monthlyFees="{ item: vehicle }">
              {{ getFormattedMoney(vehicle.capacity.sub_part_fee.amount) }}
            </template>

            <template #item.totalOutstanding="{ item: vehicle }">
              <div class="d-flex align-center">
                <span>{{
                  getFormattedMoney(vehicle.memo_current_balance)
                }}</span>
                <v-tooltip top v-if="vehicleIsOverpaying(vehicle)">
                  <template #activator="{ on, attrs }">
                    <v-icon class="ml-2" color="secondary" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Overpaid by
                    {{ getNumberOfOverpaidMonths(vehicle) }} months</span
                  >
                </v-tooltip>
              </div>
            </template>
          </Table>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="auto" class="font-weight-bold"
          >Total: {{ getFormattedMoney(total) }}
        </v-col>
      </v-row>

      <v-row
        ><v-col cols="12">
          <LabelledField label="Remarks"
            ><v-textarea
              v-model="remarks"
              class="text-field-background"
              hide-details="auto"
              dense
              outlined
              rows="2"
              auto-grow
              resizable
            ></v-textarea>
          </LabelledField>
        </v-col>
      </v-row>
      <v-row justify="end">
        <!-- generate bill -->
        <v-col cols="auto">
          <v-btn
            color="error"
            class="text-none"
            @click="$emit('goBackClicked')"
          >
            Cancel<v-icon right>mdi-close</v-icon></v-btn
          >
        </v-col>
        <v-col cols="auto">
          <v-btn
            :disabled="!formValid"
            color="success"
            class="text-none"
            type="submit"
            :loading="generatingVehicleBill"
          >
            Pay<v-icon right>mdi-cash</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
