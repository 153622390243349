var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c(_setup.BackButton,{on:{"click":function($event){return _vm.$emit('goBackClicked')}}},[_vm._v(" Back To Vehicles ")])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"text-h6 text-center font-weight-bold primary--text"},[_vm._v(" Vehicle Details ")])]),_c('v-spacer')],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.vehicle.registrationNumber)+" ")])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.ActionButton,{attrs:{"action":_setup.actions.downloadVehicleSticker},on:{"click":function($event){return _vm.$emit('downloadSticker', _vm.vehicle)}}})],1)],1),_c('v-row',{attrs:{"justify-md":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$mobileBreakpoint)?_c(_setup.Table,{attrs:{"headers":[..._setup.vehicleDetails.section1, ..._setup.vehicleDetails.section2].map(
            (item) => ({ text: item.name, value: item.name })
          ),"items":[
          [..._setup.vehicleDetails.section1, ..._setup.vehicleDetails.section2].reduce(
            (obj, item) => {
              obj[item.name] = item.value;
              return obj;
            },
            {}
          ),
        ]},scopedSlots:_vm._u([{key:`item.${_setup.stickerStatus}`,fn:function({ item: vehicleDetails }){return [_c(_setup.VehicleStatusChip,{attrs:{"status":vehicleDetails['Sticker Status']}})]}}],null,true)}):_c('v-simple-table',{staticClass:"vehicle-details-table",attrs:{"dense":""}},[_c('tbody',_vm._l((Math.max(
              _setup.vehicleDetails.section1.length,
              _setup.vehicleDetails.section2.length
            )),function(index){return _c('tr',{key:index},[(_setup.vehicleDetails.section1[index - 1])?[_c('td',{staticClass:"font-weight-bold",style:({ width: '25%' })},[_vm._v(" "+_vm._s(_setup.vehicleDetails.section1[index - 1].name)+" ")]),_c('td',{style:({ width: '25%' })},[[_vm._v(" "+_vm._s(_setup.vehicleDetails.section1[index - 1].value)+" ")]],2)]:[_c('td',{style:({ width: '25%', borderBottom: 'none' })}),_c('td',{style:({ width: '25%', borderBottom: 'none' })})],_c('td',{style:({ borderBottom: 'none' })}),(_setup.vehicleDetails.section2[index - 1])?[_c('td',{staticClass:"font-weight-bold",style:({ width: '25%' })},[_vm._v(" "+_vm._s(_setup.vehicleDetails.section2[index - 1].name)+" ")]),_c('td',{style:({ width: '25%' })},[(
                    _setup.vehicleDetails.section2[index - 1].name ===
                    'Sticker Status'
                  )?_c(_setup.VehicleStatusChip,{attrs:{"status":_setup.vehicleDetails.section2[index - 1].value}}):[_vm._v(" "+_vm._s(_setup.vehicleDetails.section2[index - 1].value)+" ")]],2)]:[_c('td',{style:({ width: '25%', borderBottom: 'none' })}),_c('td',{style:({ width: '25%', borderBottom: 'none' })})]],2)}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }