<script setup>
import { computed } from "vue";
import {
  formatVehicleCapacity,
  getFormattedBillDate,
  getFormattedMoney,
  getVehicleDatePaid,
  getVehicleValidTo,
} from "@/utils/functions";
import VehicleStatusChip from "./VehicleStatusChip.vue";
import BackButton from "../utils/BackButton.vue";
import Table from "../utils/Table.vue";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";

const props = defineProps({
  vehicle: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
});

const vehicleNotEmpty = computed(() => {
  return props.vehicle !== null;
});

const vehicleDetails = computed(() => {
  let vehicleDetails = {};
  if (vehicleNotEmpty.value) {
    vehicleDetails = getVehicleDetails(props.vehicle);
  }
  return vehicleDetails;
});

function getVehicleDetails(vehicle) {
  const vehicleDetails = {
    section1: [],
    section2: [],
  };

  vehicleDetails.section1 = [
    {
      name: "Registration Number",
      value: vehicle.registrationNumber,
    },
    {
      name: "Sacco Name",
      value: vehicle.account.client.customer_name,
    },
    {
      name: "Sacco Contact",
      value: vehicle.account.client.phone_number1,
    },
    {
      name: "Customer Name",
      value: vehicle.account.account_name,
    },
    {
      name: "Customer Contact",
      value: vehicle.account.contact_phone_number,
    },
    {
      name: "Registration Date",
      value: getFormattedBillDate(vehicle.created_at, false, false),
    },
    {
      name: "Vehicle Type",
      value: vehicle.type.type,
    },
    {
      name: "Capacity",
      value: formatVehicleCapacity(vehicle.capacity.capacity),
    },
    {
      name: "SubCounty",
      value: vehicle.subcounty.sub_county_name,
    },
  ];

  const datePaid = getVehicleDatePaid(vehicle);

  const validTo = getVehicleValidTo(vehicle);

  vehicleDetails.section2 = [
    {
      name: "Sticker Status",
      value: vehicle.eportalStatus,
    },
    {
      name: "Zone",
      value: vehicle.zone.zone_name,
    },
    {
      name: "Date Paid",
      value: datePaid ? getFormattedBillDate(datePaid, false, false) : "N/A",
    },
    {
      name: "Valid To",
      value: validTo ? getFormattedBillDate(validTo, false, false) : "N/A",
    },
    {
      name: "Arrears",
      value: getFormattedMoney(vehicle.memo_arrears),
    },
    {
      name: "Penalty",
      value: getFormattedMoney(vehicle.memo_penalty),
    },
    {
      name: "Fees",
      value: getFormattedMoney(vehicle.memo_penalty),
    },
    {
      name: "Monthly Fees",
      value: getFormattedMoney(vehicle.capacity.sub_part_fee.amount),
    },
    {
      name: "Total Outstanding",
      value: getFormattedMoney(vehicle.memo_current_balance),
    },
  ];
  return vehicleDetails;
}

const stickerStatus = "Sticker Status";
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="1">
        <BackButton @click="$emit('goBackClicked')">
          Back To Vehicles
        </BackButton>
      </v-col>
      <v-col cols="12" md="10">
        <div class="text-h6 text-center font-weight-bold primary--text">
          Vehicle Details
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row justify="center">
      <v-col class="font-weight-bold" cols="auto">
        {{ vehicle.registrationNumber }}
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <ActionButton
          :action="actions.downloadVehicleSticker"
          @click="$emit('downloadSticker', vehicle)"
        />
      </v-col>
    </v-row>
    <v-row justify-md="center">
      <v-col cols="12">
        <Table
          v-if="$mobileBreakpoint"
          :headers="
            [...vehicleDetails.section1, ...vehicleDetails.section2].map(
              (item) => ({ text: item.name, value: item.name })
            )
          "
          :items="[
            [...vehicleDetails.section1, ...vehicleDetails.section2].reduce(
              (obj, item) => {
                obj[item.name] = item.value;
                return obj;
              },
              {}
            ),
          ]"
        >
          <template #[`item.${stickerStatus}`]="{ item: vehicleDetails }">
            <VehicleStatusChip
              :status="vehicleDetails['Sticker Status']"
              class=""
            ></VehicleStatusChip>
          </template>
        </Table>

        <v-simple-table v-else class="vehicle-details-table" dense>
          <tbody>
            <!-- Ok, rendering 2 different tables would be easier 
                but if we want row heights in both tables to match up when a row in
                table 1 has taller content than a row in table 2, this is
                one way to do it
            -->
            <tr
              v-for="index in Math.max(
                vehicleDetails.section1.length,
                vehicleDetails.section2.length
              )"
              :key="index"
            >
              <template v-if="vehicleDetails.section1[index - 1]">
                <td class="font-weight-bold" :style="{ width: '25%' }">
                  {{ vehicleDetails.section1[index - 1].name }}
                </td>
                <td class="" :style="{ width: '25%' }">
                  <template>
                    {{ vehicleDetails.section1[index - 1].value }}
                  </template>
                </td>
              </template>
              <template v-else>
                <td :style="{ width: '25%', borderBottom: 'none' }"></td>
                <td :style="{ width: '25%', borderBottom: 'none' }"></td>
              </template>

              <td :style="{ borderBottom: 'none' }"></td>

              <template v-if="vehicleDetails.section2[index - 1]">
                <td class="font-weight-bold" :style="{ width: '25%' }">
                  {{ vehicleDetails.section2[index - 1].name }}
                </td>
                <td class="" :style="{ width: '25%' }">
                  <VehicleStatusChip
                    v-if="
                      vehicleDetails.section2[index - 1].name ===
                      'Sticker Status'
                    "
                    :status="vehicleDetails.section2[index - 1].value"
                    class=""
                  ></VehicleStatusChip>
                  <template v-else>
                    {{ vehicleDetails.section2[index - 1].value }}
                  </template>
                </td>
              </template>
              <template v-else>
                <td :style="{ width: '25%', borderBottom: 'none' }"></td>
                <td :style="{ width: '25%', borderBottom: 'none' }"></td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss"></style>
