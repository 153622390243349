<script setup>
import { computed, ref } from "vue";
import {
  arrayNotEmpty,
  formatVehicleCapacity,
  getFormattedMoney,
  getJsDateAsYMD,
  removeItemFromArray,
  isVehicleArchived,
  vehicleStickerExpired,
  getVehicleSticker,
} from "@/utils/functions.js";
import ActionButton from "../utils/ActionButton.vue";
import { actions } from "@/utils/constants";
import { snackbarData } from "../utils/snackbar/logic";
import VehicleStatusChip from "./VehicleStatusChip.vue";
import DownloadVehiclesCsvButton from "./DownloadVehiclesCsvButton.vue";
import Table from "../utils/Table.vue";
import { getVueInstance } from "@/utils/vue_helpers";
import Dialog from "../utils/Dialog.vue";
import LoadingButton from "../utils/LoadingButton.vue";

const props = defineProps({
  vehicles: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  bulkDownloadingStickers: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "payForVehiclesClicked",
  "bulkDownloadStickersClicked",
]);

function getValidity(vehicle) {
  const sticker = vehicle?.stickers?.[0];
  if (!!sticker) {
    const validTo = getJsDateAsYMD(new Date(sticker.valid_to));
    const datePaid = getJsDateAsYMD(new Date(sticker.vehicle_bill.date_paid));
    return `Date Paid:<br/>${datePaid}<br/>Valid To:<br/>${validTo}`;
  } else {
    return "";
  }
}

function headerCheckboxClicked() {
  if (selectionState.allVehicles.value) {
    selectedVehicles.value = [];
  } else {
    selectedVehicles.value = props.vehicles
      .filter((vehicle) => !isVehicleArchived(vehicle));
  }
}

function payForVehiclesClicked(vehicles) {
  if (arrayNotEmpty(vehicles)) {
    archivedVehiclesSelectedForPayment.value = vehicles.filter((vehicle) =>
      isVehicleArchived(vehicle)
    );
    if (arrayNotEmpty(archivedVehiclesSelectedForPayment.value)) {
      archivedVehiclesDialogOpen.value = true;
      return;
    }
    emit("payForVehiclesClicked", vehicles);
  } else {
    snackbarData.text = "Please Select a Vehicle";
    snackbarData.color = "error";
    snackbarData.open = true;
  }
}

const selectedVehicles = ref([]);

const nonArchivedVehicles = computed(() => {
  return props.vehicles.filter((vehicle) => !isVehicleArchived(vehicle));
});

const selectionState = {
  none: computed(() => selectedVehicles.value.length === 0),
  someVehicles: computed(
    () =>
      selectedVehicles.value.length !== 0 &&
      selectedVehicles.value.length < nonArchivedVehicles.value.length
  ),
  allVehicles: computed(
    () => selectedVehicles.value.length === nonArchivedVehicles.value.length
  ),
};

const { vuetify } = getVueInstance();

const tableHeaders = computed(() => {
  const headers = [];
  const smallScreen = vuetify.breakpoint.smAndDown;
  const showRowCheckboxAsFirstItem = !smallScreen;
  const rowCheckboxShouldNotBeFirstItem = smallScreen;
  if (showRowCheckboxAsFirstItem) {
    headers.push({ text: "Checkbox", value: "checkbox" });
  }
  headers.push(
    { text: "Registration No", value: "registrationNumber" },
    { text: "Contact", value: "contact" },
    { text: "Vehicle Type", value: "type" },
    { text: "Capacity", value: "capacity" },
    { text: "Total Outstanding", value: "totalOutstanding" },
    { text: "Sticker Status", value: "stickerStatus", action: true },
    { text: "View", value: "view", action: true },
    {
      text: "Download Sticker",
      value: "downloadSticker",
      action: true,
    }
  );
  if (rowCheckboxShouldNotBeFirstItem) {
    headers.push({ text: "Checkbox", value: "checkbox" });
  }
  headers.push({ text: "Pay", value: "pay", action: true });
  return headers;
});

const archivedVehiclesDialogOpen = ref(null);

const archivedVehiclesSelectedForPayment = ref([]);

const archivedVehicleRegistrationNumbersAsString = computed(() => {
  return archivedVehiclesSelectedForPayment.value
    .map((vehicle) => vehicle.registrationNumber)
    .join(",");
});

function checkVehicleIsSelected(vehicleToCheck) {
  const selectedVehicle = selectedVehicles.value.find(
    (selectedVehicle) => selectedVehicle.id === vehicleToCheck.id
  );
  return !!selectedVehicle;
}

function selectOrUnselectVehicle(vehicle) {
  const selectedVehicle = selectedVehicles.value.find(
    (selectedVehicle) => selectedVehicle.id === vehicle.id
  );
  const vehicleAlreadySelected = !!selectedVehicle;
  if (vehicleAlreadySelected) {
    removeItemFromArray(vehicle, selectedVehicles.value);
  } else {
    selectedVehicles.value.push(vehicle);
  }
}

function onBulkDownloadStickersClicked() {
  const noVehicleSelected = !arrayNotEmpty(selectedVehicles.value);
  if (noVehicleSelected) {
    snackbarData.text = "Please Select a Vehicle";
    snackbarData.color = "error";
    snackbarData.open = true;
    return;
  }
  emit("bulkDownloadStickersClicked", selectedVehicles.value);
}
</script>
<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="auto">
        <v-btn
          class="text-none"
          color="secondary"
          depressed
          @click="$emit('downloadVehicleListClicked')"
        >
          Export to PDF <v-icon right>mdi-download-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <DownloadVehiclesCsvButton>
          <template #button="{ loading }">
            <v-btn
              class="text-none lighten-1"
              color="primary"
              depressed
              :disabled="loading"
            >
              Export to CSV <v-icon right>mdi-download-circle</v-icon>
            </v-btn>
          </template>
        </DownloadVehiclesCsvButton>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="auto">
        <LoadingButton
          :class="{
            'secondary white--text': !bulkDownloadingStickers,
          }"
          :loading="bulkDownloadingStickers"
          @click="onBulkDownloadStickersClicked"
        >
          <div>
            <span>Download Stickers</span>
            <v-icon right>mdi-download-circle</v-icon>
          </div>
          <template #loadingText> Downloading Stickers </template>
        </LoadingButton>
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn
          class="text-none"
          color="success"
          depressed
          @click="payForVehiclesClicked(selectedVehicles)"
        >
          Pay For Vehicles <v-icon right>mdi-cash</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="$mobileBreakpoint && arrayNotEmpty(selectedVehicles)">
      <v-col cols="12">
        <div class="d-flex flex-wrap ml-n2 mt-n2">
          <v-chip
            v-for="(selectedVehicle, index) in selectedVehicles"
            :key="index"
            small
            class="ml-2 mt-2"
            close
            @click:close="
              removeItemFromArray(selectedVehicle.id, selectedVehicles)
            "
            @click="removeItemFromArray(selectedVehicle.id, selectedVehicles)"
          >
            {{ selectedVehicle.registrationNumber }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Table
          :headers="tableHeaders"
          :items="vehicles"
          :loading="loading"
          emptyText="No Vehicles found"
          headClasses="primary white--text"
          class="view-vehicles-table"
        >
          <template #header.checkbox>
            <v-icon
              v-if="!$mobileBreakpoint"
              color="white"
              @click="headerCheckboxClicked"
            >
              <template v-if="selectionState.none.value">
                mdi-checkbox-blank-outline
              </template>
              <template v-else-if="selectionState.someVehicles.value">
                mdi-minus-box
              </template>
              <template v-else> mdi-checkbox-marked </template>
            </v-icon>
            <div v-else>Select</div>
          </template>

          <template #item.checkbox="{ item: vehicle }">
            <v-simple-checkbox
              v-if="!isVehicleArchived(vehicle)"
              dense
              hide-details
              class="mt-md-0 pt-md-0"
              color="primary"
              :value="checkVehicleIsSelected(vehicle)"
              @input="selectOrUnselectVehicle(vehicle)"
            >
            </v-simple-checkbox>
            <span v-else></span>
          </template>

          <template #item.registrationNumber="{ item: vehicle }">
            <div v-if="isVehicleArchived(vehicle)" class="d-flex align-center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="d-flex align-center" v-on="on" v-bind="attrs">
                    <v-icon small color="error" class="">mdi-alert</v-icon>
                    <span :class="{ 'ml-2 error--text': true }">{{
                      vehicle.registrationNumber
                    }}</span>
                  </div>
                </template>
                <span>This vehicle has been archived</span>
              </v-tooltip>
            </div>

            <span v-else>{{ vehicle.registrationNumber }}</span>
          </template>

          <template #item.contact="{ item: vehicle }">
            {{ vehicle.account.contact_phone_number }}
          </template>

          <template #item.type="{ item: vehicle }">
            {{ vehicle.type.type }}
          </template>

          <template #item.capacity="{ item: vehicle }">
            {{ formatVehicleCapacity(vehicle.capacity.capacity) }}
          </template>

          <template #item.totalOutstanding="{ item: vehicle }">
            {{ getFormattedMoney(vehicle.memo_current_balance) }}
          </template>

          <template #item.stickerStatus="{ item: vehicle }">
            <VehicleStatusChip :status="vehicle.eportalStatus">
            </VehicleStatusChip>
          </template>

          <template #item.view="{ item: vehicle }">
            <ActionButton
              :action="actions.view"
              inTable
              @click="$emit('viewVehicle', vehicle)"
            />
          </template>

          <template #item.downloadSticker="{ item: vehicle }">
            <ActionButton
              :action="actions.downloadVehicleSticker"
              inTable
              @click="$emit('downloadSticker', vehicle)"
            />
          </template>

          <template #item.pay="{ item: vehicle }">
            <div :style="{ minWidth: '104px' }">
              <ActionButton
                v-if="!checkVehicleIsSelected(vehicle)"
                :action="actions.pay"
                inTable
                @click="payForVehiclesClicked([vehicle])"
              />
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>

    <Dialog :dialogOpen.sync="archivedVehiclesDialogOpen" max-width="600">
      <template #title>
        <span class="error--text">Archived Vehicle</span>
      </template>
      <template #text>
        The vehicle
        <b>{{ archivedVehicleRegistrationNumbersAsString }}</b>
        has been archived. Please visit your subcounty revenue office for
        assistance.
      </template>
    </Dialog>
  </v-container>
</template>

<style lang="scss" scoped>
:deep(.view-vehicles-table) {
  & td,
  & th {
    padding: 0 8px !important;
  }
}
</style>
